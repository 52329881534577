import React from 'react';

const StatusPill = ({ name, email, color }: { name: string; email: string; color : string }) => {

  return (
    <div className="flex gap-2">
      <div>
        <div className="relative">
          <div className="w-9 h-9 rounded-full flex items-center justify-center text-lg text-[#424242]"
               style={{backgroundColor: color}}
          >
            {name[0].toUpperCase()}
          </div>
        </div>

      </div>
      <div color="flex flex-col justify-between py-2 px-0 text-[#424242]">
        <div className="font-[600] text-[14px]">
          {name}
        </div>
        <div className="text-xs">
          {email}
        </div>

      </div>
    </div>
  );
};

export default StatusPill;
